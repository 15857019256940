import Trusted1 from '../../public/home/trusted-1.svg'
import Trusted2 from '../../public/home/trusted-2.svg'
import Trusted3 from '../../public/home/trusted-3.webp'
import Trusted4 from '../../public/home/trusted-4.webp'
import Trusted5 from '../../public/home/trusted-5.svg'
import Trusted6 from '../../public/home/trusted-6.png'
import Trusted7 from '../../public/home/trusted-7.png'
import Feature1 from '../../public/home/Feature1.jpg';
import Feature2 from '../../public/home/Feature2.jpg';
import Feature3 from '../../public/home/Feature3.jpg';
import Feature4 from '../../public/home/Feature4.jpg';
import FeatureIcon1 from '../../public/home/FeatureIcon1.svg';
import FeatureIcon2 from '../../public/home/FeatureIcon2.svg';
import FeatureIcon3 from '../../public/home/FeatureIcon3.svg';
import FeatureIcon4 from '../../public/home/FeatureIcon4.svg';
import Award1 from '../../public/home/Award1.svg'
import Award2 from '../../public/home/Award2.svg'
import AWSSingapore from '../../public/home/events/AWS CD Singapore.jpg'
import AWSVirtual from '../../public/home/events/AWS CD Virtual Edition.jpg'
import AWSSriLanka from '../../public/home/events/AWS CD Sri Lanka.jpg'
import DataOpsOb from '../../public/home/events/DataOps ObservabilityConf.jpg'
import DevNation from '../../public/home/events/DevNation.jpg'
import DevOps from '../../public/home/events/DevOps Days India.jpg'
import ExpertsLive from '../../public/home/events/Experts Live.jpg'
import Prakarsh from '../../public/home/events/Prakarsh.jpg'
import PyConfHy from '../../public/home/events/PyConf - Hyderabad.jpg'
import StartUpKumbh from '../../public/home/events/StartupKumbh.jpg'
import Tharang from '../../public/home/events/Tharang.jpg'
import Wow from '../../public/home/events/WOW.jpg'
import JecrcCloud from '../../public/home/events/JECRC CLOUD SUMMIT.jpg'
import DevFestAurangabad from '../../public/home/events/Devfest Aurangabad.jpg'
import EditFile from "../../public/img/menu/EditFile.svg"
import MoviesFolder from "../../public/img/menu/MoviesFolder.svg"


export const hostedEvents = [
	"https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-07-27T12%3A17%3A27.383Z",
	"https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-07-27T12%3A18%3A01.021Z",
	"https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-07-27T12%3A18%3A13.776Z",
	"https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-07-27T12%3A18%3A37.536Z",
	"https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-07-27T12%3A36%3A39.499Z",
	"https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-07-27T12%3A19%3A17.864Z",
	"https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-07-27T12%3A19%3A32.028Z",
	"https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-07-27T12%3A19%3A55.392Z",
	"https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-07-27T12%3A20%3A09.181Z",
	"https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-07-27T12%3A20%3A19.247Z",
	"https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-07-27T12%3A20%3A32.134Z",
	"https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-07-27T12%3A20%3A46.164Z"
]
export const resourcesSection = [
	{
	  link: "https://blog.konfhub.com/",
	  title: "Blogs",
	  subTitle: "Stay updated with everything new! ",
	  image: EditFile,
	},
	{
	  link: "https://www.youtube.com/c/KonfHubTech",
	  title: "Videos",
	  subTitle: `Check these videos on using Konfhub`,
	  image: MoviesFolder,
	},
];

export const testimonialsList = [
	{
		content: "I have been working with KonfHub team for the past 4 years or so. They know the pulse of the developer community and can tailor the suite accordingly. They have been helping AWS User Groups across India to host AWS Community Days. They are very community friendly and ready to go extra mile.",
		name: "Jeevan Dongre",
		designation: "Organiser AWS User Group Bengaluru & AWS Community Hero"
	},
	{
		content: "Red Hat worked with KonfHub to organize and execute DevNation Live 2019 in Bengaluru. We partnered with Ganesh, Hari and the KonfHub team who were professional, detail-oriented, and enthusiastically supported our very successful event. We look forward to working again with KonfHub in the future.",
		name: "Kamesh Sampath",
		designation: "Co-organizer, DevNation Live 2019"
	},
	{
		content: "I've been a Konfhub user from very early days. I appreciate the founders interest in curating, engaging and gamifying the community. KonfHub has also become a great place for Indian developers to talk about technologies from all walks of life. Be it Cloud Community Days, AI Dev Days, JCloud Day -- all invoke a sense of learning and motivates community!",
		name: "Avinash",
		designation: "Aravind Putrevu, Senior Developer Advocate, Elastic"
	}
]

export const konfhub_stats = {
	ticketSold: "104795",
	conference: "109",
	yoy: "23",
	yoyDecimal: "6",
	satisfaction: "4",
	satisfactionDecimal: "3"
}


export const featuredEventsList = {
	count: 5,
	events: [
		{
			city: "India",
			country: "India",
			event_end_timestamp: "2023-01-13 17:30:00",
			is_free: true,
			event_name: "Azuga Quizzing Challenge",
			event_poster_url: "/img/featured_events/azuga-quizzing-min.jpeg",
			event_start_timestamp: "2022-11-04 17:00:00",
			// event_url: "acd-2022",
			url: "https://quiz.konfhub.com/t/azuga",
			is_virtual: true,
			// expected_participants: 10000,
			timezone: "Asia/Kolkata",
			// venue: "India",
		},
		// {
		// 	city: "Bengaluru",
		// 	country: "India",
		// 	event_end_timestamp: "2022-11-10 17:00:00",
		// 	is_free: true,
		// 	event_name: "DevNation Day India 2022",
		// 	event_poster_url: "/img/featured_events/dev-india.png",
		// 	event_start_timestamp: "2022-11-9 08:00:00",
		// 	event_url: "devnation-22",
		// 	url: "https://developers.redhat.com/devnation/devnationday-india-2022",
		// 	// expected_participants: 1,
		// 	timezone: "Asia/Kolkata",
		// 	venue: "Radisson Blu Bengaluru, Outer Ring Road",
		// },
		// {
		// 	city: "India",
		// 	country: "India",
		// 	event_end_timestamp: "2022-11-12 14:00:00",
		// 	is_free: true,
		// 	event_name: "AWS Community Day India - Virtual Edition 2022",
		// 	event_poster_url: "/img/featured_events/aws-india.png",
		// 	event_start_timestamp: "2022-11-11 09:00:00",
		// 	event_url: "acd-2022",
		// 	is_virtual: true,
		// 	expected_participants: 10000,
		// 	timezone: "Asia/Kolkata",
		// 	// venue: "India",
		// },
		// {
		// 	city: "Dehradun",
		// 	country: "India",
		// 	event_end_timestamp: "2022-11-15 18:00:00",
		// 	is_free: true,
		// 	event_name: "AWS Community Day Dehradun",
		// 	event_poster_url: "/img/featured_events/aws-pune.png",
		// 	event_start_timestamp: "2022-11-14 10:00:00",
		// 	event_url: "devnation-22",
		// 	url: "https://konfhub.com/acdd",
		// 	// expected_participants: 1,
		// 	timezone: "Asia/Kolkata",
		// 	venue: "UPES Energy House, Misraspatti",
		// },
		{
			city: "Columbo",
			country: "Sri Lanka",
			event_end_timestamp: "2022-12-06 16:00:00",
			is_free: true,
			event_name: "AWS Community Day Sri Lanka",
			event_poster_url: "/img/featured_events/AWS-Sri-Lanka-min.png",
			event_start_timestamp: "2022-12-06 09:00:00",
			event_url: "aws-community-day-sl-2022",
			is_virtual: true,
			// expected_participants: 10000,
			timezone: "Asia/Kolkata",
			// venue: "India",
		},
		{
			city: "Pune",
			country: "India",
			event_end_timestamp: "2022-12-10 18:00:00",
			is_free: false,
			event_name: "AWS Community Day Pune 2022",
			event_poster_url: "/img/featured_events/aws-pune.png",
			event_start_timestamp: "2022-12-10 08:00:00",
			event_url: "acdp22",
			// expected_participants: 500,
			timezone: "Asia/Kolkata",
			// venue: "Pune",
		},
		{
			city: "Hyderabad",
			country: "India",
			event_end_timestamp: "2022-12-11 18:00:00",
			is_free: false,
			event_name: "PyConf Hyderabad 2022",
			event_poster_url: "/img/featured_events/pycon-hyderabad-min.png",
			event_start_timestamp: "2022-12-10 09:00:00",
			event_url: "jcs",
			expected_participants: 500,
			timezone: "Asia/Kolkata",
			url: "https://pyconf.hydpy.org/2022/",
			// venue: "Jaipur",
		},
		{
			city: "Ahmedabad",
			country: "India",
			event_end_timestamp: "2022-12-18 14:00:00",
			is_free: false,
			event_name: "AWS Community Day Ahmedabad",
			event_poster_url: "/img/featured_events/ACD-Ahmedabad-min.jpg",
			event_start_timestamp: "2022-12-17 07:30:00",
			event_url: "devnation-22",
			url: "https://awsahmedabad.community/",
			// expected_participants: 1,
			timezone: "Asia/Kolkata",
			venue: "Dr. Babasaheb Ambedkar Open University",
		},
	]
}


export const trustedByData = [
	{
		name: 'AWS',
		image: Trusted1,
		height: '50px',
		alt: 'KonfHub AWS Client'
	},
	{
		name: 'Harness',
		image: Trusted2,
		height: 'auto',
		alt: 'KonfHub Harness Client'
	},
	{
		name: 'Jet Brains',
		image: Trusted4,
		height: 40,
		alt: 'KonfHub JetBrains Client'
	},
	{
		name: 'Microsoft',
		image: Trusted3,
		height: 30,
		alt: 'KonfHub Microsoft Client'
	},
	{
		name: 'Red Hat',
		image: Trusted5,
		height: 30,
		alt: 'KonfHub RedHat Client'
	},
	{
		name: 'Snowflake',
		image: Trusted6,
		height: 30,
		alt: 'KonfHub Snowflake Client'
	},
	{
		name: 'Unravel',
		image: Trusted7,
		height: 30,
		alt: 'KonfHub Unravel Client'
	}

]

export const canDos = [
	{
		name: "Event Templates",
		image: Feature1,
		headline: "Choice from different event templates",
		description: "Many template options to choose from",
		icon: FeatureIcon1,
		alt: "Multiple template options event creation"
	},
	{
		name: "Flexible Ticketing",
		image: Feature2,
		headline: "Paid & free tickets; discount & access codes",
		description: "Because no one-size-fits-all",
		icon: FeatureIcon2,
		alt: "Flexible ticketing tool"
	},
	{
		name: "Amplify Event",
		image: Feature3,
		headline: "Get more foot-fall & make your event a “sell-out”",
		description: "Get relevant audience through gamification!",
		icon: FeatureIcon3,
		alt: "Amplify event"
	},
	{
		name: "Communicate",
		image: Feature4,
		headline: "SMS / WhatsApp messages + Unlimited emails",
		description: "Communicate effectively with event attendees",
		icon: FeatureIcon4,
		alt: "Communication with audience is easy"
	}
];

export const testimonials = [
	{
		testimonial_text: "“Red Hat worked with KonfHub to organize and execute DevNation Live 2019 in Bengaluru. We partnered with Ganesh, Hari and the KonfHub team who were professional, detail-oriented, and enthusiastically supported our very successful event. We look forward to working again with KonfHub in the future.”",
		person_name: "Kamesh Sampath",
		affiliation: "Co-organizer, DevNation Live 2019"
	},
	{
		testimonial_text: "“I have been working with KonfHub team for the past 4 years or so. They know the pulse of the developer community and can tailor the suite accordingly. They have been helping AWS User Groups across India to host AWS Community Days. They are very community friendly and ready to go extra mile.”",
		person_name: "Jeevan Dongre",
		affiliation: "Organiser AWS User Group Bengaluru & AWS Community Hero"
	},
	{
		testimonial_text: "“I've been a Konfhub user from very early days. I appreciate the founders interest in curating, engaging and gamifying the community. KonfHub has also become a great place for Indian developers to talk about technologies from all walks of life. Be it Cloud Community Days, AI Dev Days, JCloud Day -- all invoke a sense of learning and motivates community!”",
		person_name: "Aravind Putrevu",
		affiliation: "Senior Developer Advocate, Elastic"
	}
];

export const awards = [
	{
		title: "Startup Karnataka",
		awardImage: Award1,
		alt: "Startup Karnataka for Konfhub"
	},
	{
		title: "Ramaiah Evolute",
		awardImage: Award2,
		alt: "Ramaiah Evolute Startups to Success for Konfhub"
	}
];


export const hostedConferences = [
	{
		title: "AWS CD Singapore",
		img: AWSSingapore,
		link: "https://konfhub.com/aws-community-day-sg"
	},
	{
		title: "AWS CD Virtual Edition",
		img: AWSVirtual,
		link: "https://konfhub.com/acd-2022"
	},
	{
		title: "AWS CD Sri Lanka",
		img: AWSSriLanka,
		link: "https://konfhub.com/aws-community-day-sl-2022"
	},
	{
		title: "DataOps ObservabilityConf",
		img: DataOpsOb,
		link: "https://dataops.konfhub.com/"
	},
	{
		title: "DevNation",
		img: DevNation,
		link: "https://konfhub.com/devnation-22"
	},
	{
		title: "DevOps Days India",
		img: DevOps,
		link: "https://konfhub.com/db"
	},

	{
		title: "Devfest Aurangabad",
		img: DevFestAurangabad,
		link: "https://konfhub.com/devfestaurangabad22"
	},
	{
		title: "Experts Live",
		img: ExpertsLive,
		link: "https://konfhub.com/eli-2023"
	},
	{
		title: "JECRC CLOUD SUMMIT",
		img: JecrcCloud,
		link: "https://konfhub.com/jcs"
	},
	{
		title: "Prakarsh",
		img: Prakarsh,
		link: "https://prakarsh.org/"
	},
	{
		title: "PyConf - Hyderabad",
		img: PyConfHy,
		link: "https://konfhub.com/pyconf-hyd-22"
	},
	{
		title: "StartupKumbh",
		img: StartUpKumbh,
		link: "https://konfhub.com/StartupKumbh"
	},
	{
		title: "Tharang",
		img: Tharang,
		link: "http://tharang.ihrd.ac.in/"
	},
	{
		title: "WOW",
		img: Wow,
		link: "https://konfhub.com/gdscwowpune"
	},
];

export const resources = [
	{
		"id": 268,
		"title": "AWS Community Day India 2020 - Track 2",
		"resource_url": "https://youtu.be/N2I8a9jzixY",
		"resource_type": 1,
		"creator": [],
		"event_name": "AWS Community Day",
		"event_url": "https://communityday.awsug.in/",
		"published_on": "2020-10-16 00:00:00",
		"keywords": [
			"AWS"
		],
		"poster_url": "",
		"view_count": 11
	},
	{
		"id": 342,
		"title": "How AI and ‘Big Learning’ transforms life science research",
		"resource_url": "https://www.youtube.com/watch?v=tZbTvw3p548&list=PLG1Jgal0l0ohdq_ZRUqzYupZiR8QfB33g&index=12",
		"resource_type": 1,
		"creator": [
			{
				"name": "Denis Bauer",
				"affiliation": "--"
			}
		],
		"event_name": "AI Dev Days ",
		"event_url": "https://aidevdays2017.konfhub.com/aidevdays.com/index.html",
		"published_on": "2018-03-09 00:00:00",
		"keywords": [
			"AI"
		],
		"poster_url": "",
		"view_count": 40
	},
	{
		"id": 346,
		"title": "Cognifying Small Businesses",
		"resource_url": "https://www.slideshare.net/slideshow/embed_code/key/I0FSYZnnetfJIZ",
		"resource_type": 2,
		"creator": [
			{
				"name": "Balaji",
				"affiliation": "--"
			}
		],
		"event_name": "AI Dev Days ",
		"event_url": "https://aidevdays2017.konfhub.com/aidevdays.com/index.html",
		"published_on": "2018-03-13 00:00:00",
		"keywords": [
			"Business"
		],
		"poster_url": "",
		"view_count": 246
	},
	{
		"id": 364,
		"title": "Serverless: Beyond Lambda Functions",
		"resource_url": "https://www.youtube.com/embed/KSObxD557Tk",
		"resource_type": 1,
		"creator": [
			{
				"name": "Srushith Repakula",
				"affiliation": "KonfHub Techbologies"
			}
		],
		"event_name": "AWS Community Day India Virtual Edition 2020",
		"event_url": "",
		"published_on": "2020-12-08 00:00:00",
		"keywords": [
			"AWS",
			"Serverless"
		],
		"poster_url": "",
		"view_count": 9
	},
	{
		"id": 71,
		"title": "ML Ops with Azure ML & GitHub Actions",
		"resource_url": "https://youtu.be/g_9Va24T-JU",
		"resource_type": 1,
		"creator": [
			{
				"name": "Pulkit Agarwal",
				"affiliation": "--"
			}
		],
		"event_name": "AI Dev Day",
		"event_url": "https://aidevday.konfhub.com/",
		"published_on": "2022-03-10 07:55:21",
		"keywords": [
			"Github",
			"Azure",
			"Machine learning"
		],
		"poster_url": "",
		"view_count": 4
	},
	{
		"id": 345,
		"title": "Teaching Machines to Read and Answer Questions",
		"resource_url": "https://www.slideshare.net/slideshow/embed_code/key/1YDbcPONBTJ0Ws",
		"resource_type": 2,
		"creator": [
			{
				"name": "Sandya",
				"affiliation": "--"
			}
		],
		"event_name": "AI Dev Days ",
		"event_url": "https://aidevdays2017.konfhub.com/aidevdays.com/index.html",
		"published_on": "2018-03-09 00:00:00",
		"keywords": [
			"AI"
		],
		"poster_url": "",
		"view_count": 218
	},
	{
		"id": 348,
		"title": "Musicmuni - Can We Scale Art Education",
		"resource_url": "https://www.slideshare.net/slideshow/embed_code/key/5YBPKkgCRM4sCK",
		"resource_type": 2,
		"creator": [
			{
				"name": "Swapnil Gupta & Gopala Krishna",
				"affiliation": "--"
			}
		],
		"event_name": "AI Dev Days ",
		"event_url": "https://aidevdays2017.konfhub.com/aidevdays.com/index.html",
		"published_on": "2018-03-13 00:00:00",
		"keywords": [
			"ML"
		],
		"poster_url": "",
		"view_count": 249
	}
];